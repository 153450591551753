<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'SectionAttendance',
  page: {
    title: 'Section Attendance',
    meta: [{ name: 'description', content: 'Section Attendance' }],
  },
}
</script>
